import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class CourseExamService {

  constructor(private backendService: BackendService) { }

  private path = '/candidate-exams';
  private examPath = '/exam';

  saveCandidateExam(tabid: any, examId: String) {

    return this.backendService.post(this.path, { tabid: tabid, examid: examId });
  }


  getCandidateExam() {

    return this.backendService.get(this.path);
  }

  getExamList() {

    return this.backendService.get(this.examPath);
  }

  getExamsByGroupCode(examGroupCode: any) {

    return this.backendService.post(this.examPath, { groupCodeId: examGroupCode });
  }

  getDefaultExam() {

    return this.backendService.get(this.examPath + "/default");
  }

  getExamDetailsByExamid(examid: String) {

    return this.backendService.get(this.examPath + `/${examid}`);
  }

  getCandidateExambyId(examid) {
    return this.backendService.get(this.path + '/' + examid);
  }

}